.ant-col-with-margin {
    margin: 0.625rem;
}

.ant-col-align-self-end {
    align-self: flex-end;
    text-align: right;
}

.ant-row-justify-end {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 540px) {
    /***** Containers and Extras ******/
    .ant-card-extra {
        text-align: center;
    }

    .ant-row-flex-column {
        display: flex;
        flex-direction: column;
    }

    .ant-row-justify-center {
        display: flex;
        justify-content: center !important;
    }

    .ant-row-align-items-center {
        display: flex;
        align-items: center;
    }

    .ant-col-align-self-start {
        align-self: flex-start
    }

    .ant-col-align-self-center {
        align-self: center;
        text-align: center;
    }


    .ant-first-order {
        order: 1;
    }

    .ant-second-order {
        order: 2;
    }

    .ant-third-order {
        order: 3;
    }

    .ant-fourth-order {
        order: 4;
    }

    .ant-fifth-order {
        order: 5;
    }
    /***** End of Containers and Extras *****/

    /***** Tabs *****/
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .ant-tabs-tab, .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 18px 0 0px;
        transition: all 0.3s ease-in;
    }

    .ant-tabs-tab-active {
       border-bottom-color: #1890ff;
       border-bottom-width: 2px;
       border-bottom-style: solid;
    }

    .ant-tabs-ink-bar {
       display: none;
    }

    .ant-tabs-top > .ant-tabs-nav {
        margin: 0;
    }
    /***** End of Tabs *****/
}

/** Custom antd responsive classes **/
.ant-col-xs-flex-initial { flex: initial; }
.ant-col-xs-flex-auto { flex: auto; }
.ant-col-xs-flex-none { flex: none; }
.ant-col-xs-100 { flex: 100%; }
.ant-space-xs-vertical {
    flex-direction: column;
}
.ant-space-xs-gap-small {
    gap: 8px;
}
.ant-space-xs-container {
    padding: 8px;
}
@media (min-width: 576px) {
    .ant-col-sm-flex-initial { flex: initial; }
    .ant-col-sm-flex-auto { flex: auto; }
    .ant-col-sm-flex-none { flex: none; }
    .ant-col-sm-100 { flex: 100%; }
}
@media (min-width: 768px) {
    .ant-col-md-flex-initial { flex: initial; }
    .ant-col-md-flex-auto { flex: auto; }
    .ant-col-md-flex-none { flex: none; }
    .ant-space-md-horizontal {
        flex-direction: row;
    }
    .ant-col-md-75 {
        flex: 75%;
    }
    .ant-space-md-gap-none {
        gap: 0px !important; /** Override Gap **/
    }
    .ant-space-md-gap-middle {
        gap: 16px !important; /** Override Gap **/
    }
}
@media (min-width: 992px) {
    .ant-col-lg-flex-initial { flex: initial; }
    .ant-col-lg-flex-auto { flex: auto; }
    .ant-col-lg-flex-none { flex: none; }
}
@media (min-width: 1200px) {
    .ant-col-xl-flex-initial { flex: initial; }
    .ant-col-xl-flex-auto { flex: auto; }
    .ant-col-xl-flex-none { flex: none; }
}
@media (min-width: 1600px) {
    .ant-col-xxl-flex-initial { flex: initial; }
    .ant-col-xxl-flex-auto { flex: auto; }
    .ant-col-xxl-flex-none { flex: none; }
}