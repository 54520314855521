.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: #f7f4f4; }

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #eff7ff; }

.ant-checkbox-inner {
  border-radius: 50px; }

.ant-table-cell {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative; }

.ant-picker {
  max-width: 217px; }

.ant-checkbox-inner::after {
  border-radius: 50px; }

.ant-table-cell {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative; }

.ant-list-pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative; }

.ant-list-pagination > ul > li {
  border-radius: 50px;
  height: 30px;
  width: 30px; }

.ant-table-pagination.ant-pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative; }

.ant-table-pagination.ant-pagination > li:not(.ant-pagination-options),
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  height: 30px;
  width: 30px; }

.ant-pagination-jump-next
.ant-pagination-item-container
.ant-pagination-item-ellipsis,
.ant-pagination-jump-prev
.ant-pagination-item-container
.ant-pagination-item-ellipsis {
  display: flex;
  justify-content: space-around; }

.ant-select-selection--single {
  border-radius: 50px; }

div#main-campaign-table-progress.ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-default
> div
> .ant-progress-outer
> .ant-progress-inner {
  background-color: #d8d8d8; }

.ant-table-thead > tr > th {
  background-color: #ffffff;
  border-bottom: 2px solid #636363;
  white-space: nowrap; }

.ant-table-footer {
  background-color: #ffffff;
  border-top: 2px solid #636363;
  padding: 0; }

.hide-expand > .ant-table-row-expand-icon-cell div {
  display: none;
  background: black; }

.sq-input {
  border-style: solid;
  border-color: #e8e8e8;
  border-width: 1px;
  border-radius: 4px; }

.sq-input--focus {
  border: 1px solid #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.sq-input--error {
  box-shadow: inset 0px 0px 0px 3px rgba(255, 0, 0, 0.5); }

.ant-table-row-cell-break-word {
  white-space: pre-line; }

.bg-warning {
  background-color: #fcf3ce; }

.filter-bar-container {
  border-right: 0.8px solid #e8e8e8;
  border-left: 0.8px solid #e8e8e8;
  background-color: #FAFAFA;
  padding: 14px;
  margin-left: 0px !important;
  margin-right: 0px !important; }

.filter-bar-container-no-border {
  background-color: #FAFAFA;
  padding: 14px;
  margin-left: 0px !important;
  margin-right: 0px !important; }

.urllHover {
  color: grey;
  text-decoration: none;
  font-size: 12px;
  border: none;
  background: none; }

.urllHover::before {
  margin-left: auto; }

.urllHover::after, .urllHover::before {
  content: '';
  width: 0%;
  height: 2px;
  background: #1890ff;
  display: block;
  transition: 0.5s; }

.urllHover:hover::after, .urllHover:hover::before {
  width: 100%; }

:root {
  --ant-primary-color: #1890FF;
  --mj-sidebar-color: #001529;
  /* theme color variables to use in RGB declarations */
  --primary-red: 24;
  --primary-green: 144;
  --primary-blue: 255;
  /* the threshold at which colors are considered "light". 
    Range: decimals from 0 to 1, recommended 0.5 - 0.6 */
  --threshold: 0.5;
  /* the threshold at which a darker border will be applied.
    Range: decimals from 0 to 1, recommended 0.8+ */
  --border-threshold: 0.8; }

.ant-btn-primary:not([disabled]) {
  /* sets the background for the base class */
  border-color: rgba(24, 144, 255, 1) !important;
  border-color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important;
  background: rgba(24, 144, 255, 1) !important;
  background: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }
  .ant-btn-primary:not([disabled]) span {
    /* calculates perceived lightness using the sRGB Luma method 
        Luma = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255 */
    --r: calc(var(--primary-red) * 0.2126);
    --g: calc(var(--primary-green) * 0.7152);
    --b: calc(var(--primary-blue) * 0.0722);
    --sum: calc(var(--r) + var(--g) + var(--b));
    --perceived-lightness: calc(var(--sum) / 255);
    /* shows either white or black color depending on perceived darkness */
    color: hsl(0, 0%, calc((var(--perceived-lightness) - 0.5) * -10000000%)) !important;
    color: hsl(0, 0%, calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)) !important; }

.ant-menu {
  /* sets the background for the base class */
  border-color: inherit !important;
  background: inherit !important; }
  .ant-menu .ant-menu-submenu-title > span > span > a {
    transition: color 0s; }
  .ant-menu .ant-menu-item,
  .ant-menu .ant-menu-item:hover,
  .ant-menu .ant-menu-item > span,
  .ant-menu .ant-menu-item > span > a,
  .ant-menu .ant-menu-item > span > a > span,
  .ant-menu .ant-menu-submenu > .ant-menu-submenu-title span > a,
  .ant-menu .ant-menu-item-group-title {
    /* calculates perceived lightness using the sRGB Luma method 
        Luma = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255 */
    --s-r: calc(var(--sidebar-red) * 0.2126);
    --s-g: calc(var(--sidebar-green) * 0.7152);
    --s-b: calc(var(--sidebar-blue) * 0.0722);
    --s-sum: calc(var(--s-r) + var(--s-g) + var(--s-b));
    --s-perceived-lightness: calc(var(--s-sum) / 255);
    /* shows either white or black color depending on perceived darkness */
    color: hsl(0, 0%, calc((var(--s-perceived-lightness) - 0.5) * -10000000%)) !important;
    color: hsl(0, 0%, calc((var(--s-perceived-lightness) - var(--threshold)) * -10000000%)) !important;
    background: inherit !important; }
  .ant-menu .ant-menu-item-selected,
  .ant-menu .ant-menu-item.ant-menu-item-active,
  .ant-menu .ant-menu-submenu-active {
    background: rgba(calc(var(--sidebar-red) - 50), calc(var(--sidebar-green) - 50), calc(var(--sidebar-blue) - 50), 1) !important; }
    .ant-menu .ant-menu-item-selected > span,
    .ant-menu .ant-menu-item-selected > span > a,
    .ant-menu .ant-menu-item.ant-menu-item-active > span,
    .ant-menu .ant-menu-item.ant-menu-item-active > span > a,
    .ant-menu .ant-menu-submenu-active > span,
    .ant-menu .ant-menu-submenu-active > span > a {
      background: rgba(calc(var(--sidebar-red) - 50), calc(var(--sidebar-green) - 50), calc(var(--sidebar-blue) - 50), 1) !important; }
  .ant-menu .ant-menu-submenu-active > .ant-menu-submenu-title span > a {
    background: rgba(calc(var(--sidebar-red) - 50), calc(var(--sidebar-green) - 50), calc(var(--sidebar-blue) - 50), 1) !important; }

.custom-sidebar-color {
  /* sets the background for the base class */
  border-color: rgba(var(--sidebar-red), var(--sidebar-green), var(--sidebar-blue), 1) !important;
  background: rgba(var(--sidebar-red), var(--sidebar-green), var(--sidebar-blue), 1) !important; }
  .custom-sidebar-color.collapsed {
    border-right: 0.8px solid #e8e8e8;
    height: 100%;
    width: 100%;
    position: absolute; }

.ant-layout-sider {
  background: rgba(var(--sidebar-red), var(--sidebar-green), var(--sidebar-blue), 1) !important; }

.ant-switch-checked,
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background: rgba(24, 144, 255, 1) !important;
  background: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgba(24, 144, 255, 1) !important;
  background-color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important;
  border-color: rgba(24, 144, 255, 1) !important;
  border-color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-checkbox:hover .ant-checkbox-inner {
  border-color: rgba(24, 144, 255, 1) !important;
  border-color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-tabs-tab:hover {
  color: rgba(24, 144, 255, 1) !important;
  color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgba(24, 144, 255, 1) !important;
  color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-tabs-ink-bar {
  height: 5px;
  background: rgba(24, 144, 255, 1) !important;
  background: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover,
.ant-pagination-item-active,
.ant-pagination-item:focus-visible,
.ant-pagination-item:hover,
.ant-pagination-next,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: rgba(24, 144, 255, 1);
  border-color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1); }

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 1) !important;
  box-shadow: 0 0 0 2px rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a,
.ant-pagination-item-active a {
  color: rgba(24, 144, 255, 1) !important;
  color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-pagination-next:hover,
.ant-pagination-prev:hover
.ant-pagination-item-link {
  color: rgba(24, 144, 255, 1) !important;
  color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important;
  border-color: rgba(24, 144, 255, 1) !important;
  border-color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-btn-default:hover {
  border-color: rgba(24, 144, 255, 1) !important;
  border-color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }
  .ant-btn-default:hover > span {
    color: rgba(24, 144, 255, 1) !important;
    color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-spin-dot-item {
  background-color: rgba(24, 144, 255, 1) !important;
  background-color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: rgba(24, 144, 255, 1) !important;
  background-color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important;
  border-color: rgba(24, 144, 255, 1) !important;
  border-color: rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1) !important; }

.ant-col-with-margin {
    margin: 0.625rem;
}

.ant-col-align-self-end {
    align-self: flex-end;
    text-align: right;
}

.ant-row-justify-end {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 540px) {
    /***** Containers and Extras ******/
    .ant-card-extra {
        text-align: center;
    }

    .ant-row-flex-column {
        display: flex;
        flex-direction: column;
    }

    .ant-row-justify-center {
        display: flex;
        justify-content: center !important;
    }

    .ant-row-align-items-center {
        display: flex;
        align-items: center;
    }

    .ant-col-align-self-start {
        align-self: flex-start
    }

    .ant-col-align-self-center {
        align-self: center;
        text-align: center;
    }


    .ant-first-order {
        order: 1;
    }

    .ant-second-order {
        order: 2;
    }

    .ant-third-order {
        order: 3;
    }

    .ant-fourth-order {
        order: 4;
    }

    .ant-fifth-order {
        order: 5;
    }
    /***** End of Containers and Extras *****/

    /***** Tabs *****/
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .ant-tabs-tab, .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 18px 0 0px;
        transition: all 0.3s ease-in;
    }

    .ant-tabs-tab-active {
       border-bottom-color: #1890ff;
       border-bottom-width: 2px;
       border-bottom-style: solid;
    }

    .ant-tabs-ink-bar {
       display: none;
    }

    .ant-tabs-top > .ant-tabs-nav {
        margin: 0;
    }
    /***** End of Tabs *****/
}

/** Custom antd responsive classes **/
.ant-col-xs-flex-initial { flex: initial; }
.ant-col-xs-flex-auto { flex: auto; }
.ant-col-xs-flex-none { flex: none; }
.ant-col-xs-100 { flex: 100% 1; }
.ant-space-xs-vertical {
    flex-direction: column;
}
.ant-space-xs-gap-small {
    grid-gap: 8px;
    gap: 8px;
}
.ant-space-xs-container {
    padding: 8px;
}
@media (min-width: 576px) {
    .ant-col-sm-flex-initial { flex: initial; }
    .ant-col-sm-flex-auto { flex: auto; }
    .ant-col-sm-flex-none { flex: none; }
    .ant-col-sm-100 { flex: 100% 1; }
}
@media (min-width: 768px) {
    .ant-col-md-flex-initial { flex: initial; }
    .ant-col-md-flex-auto { flex: auto; }
    .ant-col-md-flex-none { flex: none; }
    .ant-space-md-horizontal {
        flex-direction: row;
    }
    .ant-col-md-75 {
        flex: 75% 1;
    }
    .ant-space-md-gap-none {
        grid-gap: 0px !important;
        gap: 0px !important; /** Override Gap **/
    }
    .ant-space-md-gap-middle {
        grid-gap: 16px !important;
        gap: 16px !important; /** Override Gap **/
    }
}
@media (min-width: 992px) {
    .ant-col-lg-flex-initial { flex: initial; }
    .ant-col-lg-flex-auto { flex: auto; }
    .ant-col-lg-flex-none { flex: none; }
}
@media (min-width: 1200px) {
    .ant-col-xl-flex-initial { flex: initial; }
    .ant-col-xl-flex-auto { flex: auto; }
    .ant-col-xl-flex-none { flex: none; }
}
@media (min-width: 1600px) {
    .ant-col-xxl-flex-initial { flex: initial; }
    .ant-col-xxl-flex-auto { flex: auto; }
    .ant-col-xxl-flex-none { flex: none; }
}
/** XS Devices **/
#basket-items-title {
    display: block;
    font-size: 1.17em;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    margin: 5px;
}

/** MD Devices **/
@media (min-width: 768px) {
    #basket-items-title {
        margin-bottom: 0.5em;
        color: rgba(0,0,0,.85);
        font-weight: 600;
        font-size: 30px;
        line-height: 1.35;
    }
}

@media (min-width: 992px) {
    #basket-items-lg-table {
        position: absolute;
        width: 100%;
    }
}
.requester-table .ant-table-thead > tr > th {
  background-color: #fafafa;
}

.requester-table .ant-table-footer {
  background-color: #fafafa;
  padding: 20px;
}

.requester-table-header {
  background-color: #fafafa;
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.requester-table-wrapper {
  border: 1px solid #f0f0f0;
}

.requester-table .ant-table-summary {
  background-color: #fafafa;
}

.requester-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 1px solid #f0f0f0;
}

.requester-summary .ant-steps-item-title {
  font-size: 14px; /* Replace with your desired font size */
}

.ant-collapse-header {
  background-color: #ffffff; /* Replace with your desired header background color */
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-col.campaign-requester-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 85px;
  z-index: 1;
}

.campaign-requester-summary {
  position: -webkit-sticky; /* For Safari */
  min-height: 25vh;
  max-height: 60vh;
  overflow: auto;
}
.campaign-requester-summary .ant-steps-item-finish .ant-steps-item-icon {
  border-color: transparent; /* Change the border color for finished steps */
}

.campaign-requester-summary .anticon.anticon-check.ant-steps-finish-icon {
  color: #52c41a;
}
.campaign-requester-summary .ant-steps-item-wait .ant-steps-item-icon {
  background-color: transparent; /* Change the background color for waiting steps */
  border-color: transparent; /* Change the border color for waiting steps */
}

.campaign-requester-summary .ant-steps-item {
  margin-bottom: -14px;
}

.requester-table-wrapper .ant-table-summary {
  z-index: 1;
}

.campaign-order-queue-tabs .ant-tabs-nav {
  background-color: white !important;
  margin: -8px -24px !important;
  padding: 8px 24px;
}

.campaign-orderqueue-summary {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 84px; /* Adjust this value as needed */
  z-index: 1; /* Ensure it stays on top of other elements if necessary */
}

.media-carousel {
  width: auto !important;
  max-height: 380px !important;
}

.media-carousel-img__wrapper {
  display: flex !important;
  justify-content: center !important;
}

.deal-id-tag .anticon-close {
  position: absolute;
  right: 8px;
  top: 8px;
}

.ant-descriptions-bordered .ant-descriptions-view {
    border: 0px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
    border-right: 0px;
}

.ant-descriptions-bordered .ant-descriptions-item-content > span {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 639px) {
    .ant-card-body {
        padding: 16px;
    }

    .ant-card-head-title {
        font-size: 0.925rem;
    }

    .ant-descriptions-bordered .ant-descriptions-item-label, 
    .ant-descriptions-bordered .ant-descriptions-item-content {
        padding: 5px 10px;
        font-size: 0.725rem;
    }
}

@media screen and (max-width: 320px) {
    .ant-card-body {
        padding: 7px;
    }

    .ant-descriptions-bordered .ant-descriptions-item-label, 
    .ant-descriptions-bordered .ant-descriptions-item-content {
        padding: 4px 6px;
    }
}

@media (min-width: 992px) {
    #roll-up-filters {
        width: 750px;
    }
  }
html {
    height: 100%;
}

.text-center {
    text-align: center;
}

.container {
    padding: 0.5rem 1.5rem;
    text-align: justify;
}

.d-block {
    display: block;
}

ol {
    margin: 1rem 0;
}

ol li {
    margin: 1rem 0;
}

.order-count {
    counter-reset: item;
}

.order-count .order-count-list {
    display: block;
    position: relative;
}

.order-count .order-count-list:before {
    content: counters(item, ".")".";
    counter-increment: item;
    position: absolute;
    margin-right: 100%;
    right: 1rem;
}

.order-alphabet {
    list-style: lower-alpha;
}

.order-roman {
    list-style: lower-roman;
}
.tableSelectedRow {
  background: #e7effb;
  border-right: 10px solid #1665d8;
}
.tableDangerRow {
  background-color: #ffcaca;
}

.tableSuccessRow {
  background-color: #cfffe1;
}

.tableWarningRow {
  background-color: #fcf3ce;
}

@media screen and (max-width: 500px) {
  .ant-card-head-wrapper {
    flex-direction: column !important;
  }

  .ant-card-head-title {
    padding-bottom: 0;
  }

  .ant-card-extra {
    margin-left: 0;
  }

  .ant-card-extra .ant-row.ant-row-end {
    justify-content: center;
  }

  .ant-card-extra .ant-row .ant-col {
    margin: 3px;
  }
}

#paid-search-main-all-time-switch {
    background-color: rgba(0, 0, 0, 0.25);
    height: 36px;
    width: 160px;
}

#paid-search-main-share-button {
    width: 170px;
    height: 36px;
    font-size: 14px;
}

#paid-search-main-admin-button {
    width: 160px;
    height: 36px;
    font-size: 14px;
}

#paid-search-main-right-content {
    margin: 12px;
}
#paid-search-main-all-time-switch .ant-switch-handle {
    width: 32px;
    height: 31px;
    border: 6px solid white;
    border-radius: 73px;
    left: 4px;
  }

  #paid-search-main-all-time-switch .ant-switch-inner {
    font-size: 14px;
  }

  #paid-search-main-all-time-switch.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 35px - 2px);
  }

  

.page-header-bar-icon {
  margin-right: 10px;
  color: black;
  font-size: 20px;
}

.card-header-title-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-body-style {
  padding: 0px;
  height: 650px;
}

.col-category-list {
  border-right: 0.8px solid #E7E7E7;
  padding: 0px;
  height: 650px;
  overflow-y: scroll;
  height: 650px;
  max-height: 650px;
}

.col-sub-category-list {
  border-right: 0.8px solid #E7E7E7;
  padding: 0px;
  overflow-y: scroll;
  height: 650px;
  max-height: 650px;
}

.col-empty-view {
  text-align: center;
  padding-top: 5em;
  font-size: 21px;
}

.empty-view-text {
  color: #8B8B8C;
  margin-top: 20px;
}

.empty-view-text-description { color: #8B8B8C }

.card-body-row-style {
  min-height: 650px;
  height: 650px;
  max-height: 100%;
}

.search-results-view-style {
  border-right: 0.8px solid #E7E7E7;
  padding: 0px;
  overflow-y: scroll;
  height: 650px;
}

.sidebar-card {
  height: 100%;
  min-height: 80vh;
}

.gauge {
  height: 140px;
  margin-bottom: 1em;
}

.search-input {
  width: 25em;
}

.search-input-mobile {
  width: 100%;
}


.col-category-parent {
  padding: 2%;
}

/* @media only screen and (max-width: 767px) {
  .search-input {
    width: 100%;
    margin-bottom: 0.5em;
  }
} */

@media only screen and (max-width: 576) {
  .search-input {
    width: 100%;
    margin-bottom: 0.5em;
  }

  .ant-card-head {
    padding: 4px 8px; /* Adjust the padding as needed */
  }

  .ant-card-head-title {
    width: 100%;
  }
}

/* Mobile Screen iPhone, Pixel */
@media (max-width: 575px) {
  .search-input {
    width: 100%;
    margin-bottom: 0.5em;
  }
  
  .ant-card-head {
    padding: 4px 8px; /* Adjust the padding as needed */
  }

  .ant-card-head-title {
    width: 100%;
    text-align: center;
  }

  .horizontal-scroll-container {
    overflow-x: auto;
    white-space: nowrap;
  }
  
}

.custom-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #ddd;
}

.custom-collapse .ant-collapse-item {
  border-bottom: none;
}

.custom-collapse .ant-collapse-header {
  border-bottom: none;
  font-weight: bold;
}

.custom-collapse .ant-collapse-content {
  border-top: none;
}

.mobileScreenStyle {
  display: "inline-block";
  cursor: "pointer";
  border: "1px solid black";
  margin-bottom: "0.7em";
  margin-right: "0.5em";
  border-radius: "6px";
  padding: "5px 22px";
}
/* Target the DatePicker popup dropdown with high specificity */
.ant-picker-dropdown {
  box-shadow: none !important;
}

/* In case there's additional shadow on inner elements, target those too */
.ant-picker-dropdown .ant-picker-panel-container {
  box-shadow: none !important;
}

/* Fallback to target specific parts like 'content' and 'select dropdowns' */
.ant-picker-dropdown .ant-picker-content,
.ant-picker-dropdown .ant-select-dropdown {
  box-shadow: none !important;
}

.task-date-picker {
  opacity: 0;
}
.task-date-picker .ant-picker-input,
.ant-picker-footer {
  display: none;
}

