
.page-header-bar-icon {
  margin-right: 10px;
  color: black;
  font-size: 20px;
}

.card-header-title-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-body-style {
  padding: 0px;
  height: 650px;
}

.col-category-list {
  border-right: 0.8px solid #E7E7E7;
  padding: 0px;
  height: 650px;
  overflow-y: scroll;
  height: 650px;
  max-height: 650px;
}

.col-sub-category-list {
  border-right: 0.8px solid #E7E7E7;
  padding: 0px;
  overflow-y: scroll;
  height: 650px;
  max-height: 650px;
}

.col-empty-view {
  text-align: center;
  padding-top: 5em;
  font-size: 21px;
}

.empty-view-text {
  color: #8B8B8C;
  margin-top: 20px;
}

.empty-view-text-description { color: #8B8B8C }

.card-body-row-style {
  min-height: 650px;
  height: 650px;
  max-height: 100%;
}

.search-results-view-style {
  border-right: 0.8px solid #E7E7E7;
  padding: 0px;
  overflow-y: scroll;
  height: 650px;
}

.sidebar-card {
  height: 100%;
  min-height: 80vh;
}

.gauge {
  height: 140px;
  margin-bottom: 1em;
}

.search-input {
  width: 25em;
}

.search-input-mobile {
  width: 100%;
}


.col-category-parent {
  padding: 2%;
}

/* @media only screen and (max-width: 767px) {
  .search-input {
    width: 100%;
    margin-bottom: 0.5em;
  }
} */

@media only screen and (max-width: 576) {
  .search-input {
    width: 100%;
    margin-bottom: 0.5em;
  }

  .ant-card-head {
    padding: 4px 8px; /* Adjust the padding as needed */
  }

  .ant-card-head-title {
    width: 100%;
  }
}

/* Mobile Screen iPhone, Pixel */
@media (max-width: 575px) {
  .search-input {
    width: 100%;
    margin-bottom: 0.5em;
  }
  
  .ant-card-head {
    padding: 4px 8px; /* Adjust the padding as needed */
  }

  .ant-card-head-title {
    width: 100%;
    text-align: center;
  }

  .horizontal-scroll-container {
    overflow-x: auto;
    white-space: nowrap;
  }
  
}

.custom-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #ddd;
}

.custom-collapse .ant-collapse-item {
  border-bottom: none;
}

.custom-collapse .ant-collapse-header {
  border-bottom: none;
  font-weight: bold;
}

.custom-collapse .ant-collapse-content {
  border-top: none;
}

.mobileScreenStyle {
  display: "inline-block";
  cursor: "pointer";
  border: "1px solid black";
  margin-bottom: "0.7em";
  margin-right: "0.5em";
  border-radius: "6px";
  padding: "5px 22px";
}