.tableSelectedRow {
  background: #e7effb;
  border-right: 10px solid #1665d8;
}
.tableDangerRow {
  background-color: #ffcaca;
}

.tableSuccessRow {
  background-color: #cfffe1;
}

.tableWarningRow {
  background-color: #fcf3ce;
}

@media screen and (max-width: 500px) {
  .ant-card-head-wrapper {
    flex-direction: column !important;
  }

  .ant-card-head-title {
    padding-bottom: 0;
  }

  .ant-card-extra {
    margin-left: 0;
  }

  .ant-card-extra .ant-row.ant-row-end {
    justify-content: center;
  }

  .ant-card-extra .ant-row .ant-col {
    margin: 3px;
  }
}
