#paid-search-main-all-time-switch {
    background-color: rgba(0, 0, 0, 0.25);
    height: 36px;
    width: 160px;
}

#paid-search-main-share-button {
    width: 170px;
    height: 36px;
    font-size: 14px;
}

#paid-search-main-admin-button {
    width: 160px;
    height: 36px;
    font-size: 14px;
}

#paid-search-main-right-content {
    margin: 12px;
}
#paid-search-main-all-time-switch .ant-switch-handle {
    width: 32px;
    height: 31px;
    border: 6px solid white;
    border-radius: 73px;
    left: 4px;
  }

  #paid-search-main-all-time-switch .ant-switch-inner {
    font-size: 14px;
  }

  #paid-search-main-all-time-switch.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 35px - 2px);
  }

  