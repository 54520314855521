.ant-descriptions-bordered .ant-descriptions-view {
    border: 0px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
    border-right: 0px;
}

.ant-descriptions-bordered .ant-descriptions-item-content > span {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 639px) {
    .ant-card-body {
        padding: 16px;
    }

    .ant-card-head-title {
        font-size: 0.925rem;
    }

    .ant-descriptions-bordered .ant-descriptions-item-label, 
    .ant-descriptions-bordered .ant-descriptions-item-content {
        padding: 5px 10px;
        font-size: 0.725rem;
    }
}

@media screen and (max-width: 320px) {
    .ant-card-body {
        padding: 7px;
    }

    .ant-descriptions-bordered .ant-descriptions-item-label, 
    .ant-descriptions-bordered .ant-descriptions-item-content {
        padding: 4px 6px;
    }
}

@media (min-width: 992px) {
    #roll-up-filters {
        width: 750px;
    }
  }