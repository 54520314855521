.requester-table .ant-table-thead > tr > th {
  background-color: #fafafa;
}

.requester-table .ant-table-footer {
  background-color: #fafafa;
  padding: 20px;
}

.requester-table-header {
  background-color: #fafafa;
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.requester-table-wrapper {
  border: 1px solid #f0f0f0;
}

.requester-table .ant-table-summary {
  background-color: #fafafa;
}

.requester-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 1px solid #f0f0f0;
}

.requester-summary .ant-steps-item-title {
  font-size: 14px; /* Replace with your desired font size */
}

.ant-collapse-header {
  background-color: #ffffff; /* Replace with your desired header background color */
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-col.campaign-requester-sticky {
  position: sticky;
  top: 85px;
  z-index: 1;
}

.campaign-requester-summary {
  position: -webkit-sticky; /* For Safari */
  min-height: 25vh;
  max-height: 60vh;
  overflow: auto;
}
.campaign-requester-summary .ant-steps-item-finish .ant-steps-item-icon {
  border-color: transparent; /* Change the border color for finished steps */
}

.campaign-requester-summary .anticon.anticon-check.ant-steps-finish-icon {
  color: #52c41a;
}
.campaign-requester-summary .ant-steps-item-wait .ant-steps-item-icon {
  background-color: transparent; /* Change the background color for waiting steps */
  border-color: transparent; /* Change the border color for waiting steps */
}

.campaign-requester-summary .ant-steps-item {
  margin-bottom: -14px;
}

.requester-table-wrapper .ant-table-summary {
  z-index: 1;
}
