@import "./styles.css";

:root {
    --ant-primary-color: #1890FF;
    --mj-sidebar-color: #001529;

    /* theme color variables to use in RGB declarations */
    --primary-red: 24;
    --primary-green: 144;
    --primary-blue: 255;
    // --sidebar-red: 0;
    // --sidebar-green: 21;
    // --sidebar-blue: 41;
    /* the threshold at which colors are considered "light". 
    Range: decimals from 0 to 1, recommended 0.5 - 0.6 */
    --threshold: 0.5;
    /* the threshold at which a darker border will be applied.
    Range: decimals from 0 to 1, recommended 0.8+ */
    --border-threshold: 0.8;
}

@function custom-theme-color() {
    @return rgba(var(--primary-red), var(--primary-green), var(--primary-blue), 1);
}

@function custom-theme-darker-color() {
    @return rgba(calc(var(--sidebar-red) - 50), calc(var(--sidebar-green) - 50), calc(var(--sidebar-blue) - 50), 1);
}

@function custom-sidebar-color() {
    @return rgba(var(--sidebar-red), var(--sidebar-green), var(--sidebar-blue), 1);
}

.ant-btn-primary:not([disabled]) {

    /* sets the background for the base class */
    border-color: custom-theme-color() !important;
    background: custom-theme-color() !important;

    span {

        /* calculates perceived lightness using the sRGB Luma method 
        Luma = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255 */
        --r: calc(var(--primary-red) * 0.2126);
        --g: calc(var(--primary-green) * 0.7152);
        --b: calc(var(--primary-blue) * 0.0722);
        --sum: calc(var(--r) + var(--g) + var(--b));
        --perceived-lightness: calc(var(--sum) / 255);

        /* shows either white or black color depending on perceived darkness */
        color: hsl(0, 0%, calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)) !important; 
    }
}

.ant-menu {

    /* sets the background for the base class */
    border-color: inherit !important;
    background: inherit !important;

    .ant-menu-submenu-title > span > span > a {
        transition: color 0s
    }
    .ant-menu-item,
    .ant-menu-item:hover,
    .ant-menu-item > span,
    .ant-menu-item > span > a,
    .ant-menu-item > span > a > span,
    .ant-menu-submenu > .ant-menu-submenu-title span > a,
    .ant-menu-item-group-title {
        /* calculates perceived lightness using the sRGB Luma method 
        Luma = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255 */
        --s-r: calc(var(--sidebar-red) * 0.2126);
        --s-g: calc(var(--sidebar-green) * 0.7152);
        --s-b: calc(var(--sidebar-blue) * 0.0722);
        --s-sum: calc(var(--s-r) + var(--s-g) + var(--s-b));
        --s-perceived-lightness: calc(var(--s-sum) / 255);

        /* shows either white or black color depending on perceived darkness */
        color: hsl(0, 0%, calc((var(--s-perceived-lightness) - var(--threshold)) * -10000000%)) !important; 

        background: inherit !important;
    }

    .ant-menu-item-selected,
    .ant-menu-item.ant-menu-item-active,
    .ant-menu-submenu-active {
        background: custom-theme-darker-color() !important;
        &> span,
        &> span > a {
            background: custom-theme-darker-color() !important;
        }
    }

    .ant-menu-submenu-active > .ant-menu-submenu-title span > a {
        background: custom-theme-darker-color() !important;
    }
}

.custom-sidebar-color {
    /* sets the background for the base class */
    border-color: custom-sidebar-color() !important;
    background: custom-sidebar-color() !important;

    &.collapsed {
        border-right: 0.8px solid #e8e8e8;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

.ant-layout-sider {
    background: custom-sidebar-color() !important;
}

.ant-switch-checked,
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background: custom-theme-color() !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: custom-theme-color() !important;
    border-color: custom-theme-color() !important;
}

.ant-checkbox:hover .ant-checkbox-inner {
    border-color: custom-theme-color() !important;
}

.ant-tabs-tab:hover {
    color: custom-theme-color() !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: custom-theme-color() !important;
}

.ant-tabs-ink-bar {
    height: 5px;
    background: custom-theme-color() !important;
  }

.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover,
.ant-pagination-item-active,
.ant-pagination-item:focus-visible,
.ant-pagination-item:hover,
.ant-pagination-next,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: custom-theme-color();
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 2px custom-theme-color() !important;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a,
.ant-pagination-item-active a {
    color: custom-theme-color() !important;
}

.ant-pagination-next:hover,
.ant-pagination-prev:hover
.ant-pagination-item-link {
    color: custom-theme-color() !important;
    border-color: custom-theme-color() !important;
}

.ant-btn-default:hover {
    border-color: custom-theme-color() !important;
    &>span {
        color: custom-theme-color() !important;
    }
}

.ant-spin-dot-item {
    background-color: custom-theme-color() !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: custom-theme-color() !important;
    border-color: custom-theme-color() !important;
}