.campaign-order-queue-tabs .ant-tabs-nav {
  background-color: white !important;
  margin: -8px -24px !important;
  padding: 8px 24px;
}

.campaign-orderqueue-summary {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 84px; /* Adjust this value as needed */
  z-index: 1; /* Ensure it stays on top of other elements if necessary */
}

.media-carousel {
  width: auto !important;
  max-height: 380px !important;
}

.media-carousel-img__wrapper {
  display: flex !important;
  justify-content: center !important;
}

.deal-id-tag .anticon-close {
  position: absolute;
  right: 8px;
  top: 8px;
}
