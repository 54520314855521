/* Target the DatePicker popup dropdown with high specificity */
.ant-picker-dropdown {
  box-shadow: none !important;
}

/* In case there's additional shadow on inner elements, target those too */
.ant-picker-dropdown .ant-picker-panel-container {
  box-shadow: none !important;
}

/* Fallback to target specific parts like 'content' and 'select dropdowns' */
.ant-picker-dropdown .ant-picker-content,
.ant-picker-dropdown .ant-select-dropdown {
  box-shadow: none !important;
}

.task-date-picker {
  opacity: 0;
}
.task-date-picker .ant-picker-input,
.ant-picker-footer {
  display: none;
}
