.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: #f7f4f4;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #eff7ff;
}
.ant-checkbox-inner {
  border-radius: 50px;
}
.ant-table-cell {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}
.ant-picker{
  max-width: 217px;
}
.ant-checkbox-inner::after {
  border-radius: 50px;
}
.ant-table-cell {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}
.ant-list-pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.ant-list-pagination > ul > li {
  border-radius: 50px;
  height: 30px;
  width: 30px;
}
.ant-table-pagination.ant-pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.ant-table-pagination.ant-pagination > li:not(.ant-pagination-options),
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  height: 30px;
  width: 30px;
}
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  display: flex;
  justify-content: space-around;
}
.ant-select-selection--single {
  border-radius: 50px;
}

div#main-campaign-table-progress.ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-default
  > div
  > .ant-progress-outer
  > .ant-progress-inner {
  background-color: #d8d8d8;
}

.ant-table-thead > tr > th {
  background-color: #ffffff;
  border-bottom: 2px solid #636363;
  white-space: nowrap;
}

.ant-table-footer {
  background-color: #ffffff;
  border-top: 2px solid #636363;
  padding: 0;
}

.hide-expand > .ant-table-row-expand-icon-cell div {
  display: none;
  background: black;
}

.sq-input {
  border-style: solid;
  border-color: #e8e8e8;
  border-width: 1px;
  border-radius: 4px;
}

.sq-input--focus {
  border: 1px solid #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.sq-input--error {
  box-shadow: inset 0px 0px 0px 3px rgba(255, 0, 0, 0.5);
}

.ant-table-row-cell-break-word {
  white-space: pre-line;
}

.bg-warning {
  background-color: #fcf3ce;
}

.filter-bar-container {
  border-right: 0.8px solid #e8e8e8;
  border-left: 0.8px solid #e8e8e8;
  background-color: #FAFAFA;
  padding: 14px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.filter-bar-container-no-border {
  background-color: #FAFAFA;
  padding: 14px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.urllHover {
  color: grey;
  text-decoration: none;
  font-size: 12px;
  border: none;
  background: none;
}

.urllHover::before {
  margin-left: auto;
}

.urllHover::after, .urllHover::before {
  content: '';
  width: 0%;
  height: 2px;
  background: #1890ff;
  display: block;
  transition: 0.5s;
}

.urllHover:hover::after, .urllHover:hover::before {
  width: 100%;
}