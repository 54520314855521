html {
    height: 100%;
}

.text-center {
    text-align: center;
}

.container {
    padding: 0.5rem 1.5rem;
    text-align: justify;
}

.d-block {
    display: block;
}

ol {
    margin: 1rem 0;
}

ol li {
    margin: 1rem 0;
}

.order-count {
    counter-reset: item;
}

.order-count .order-count-list {
    display: block;
    position: relative;
}

.order-count .order-count-list:before {
    content: counters(item, ".")".";
    counter-increment: item;
    position: absolute;
    margin-right: 100%;
    right: 1rem;
}

.order-alphabet {
    list-style: lower-alpha;
}

.order-roman {
    list-style: lower-roman;
}