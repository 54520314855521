/** XS Devices **/
#basket-items-title {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    margin: 5px;
}

/** MD Devices **/
@media (min-width: 768px) {
    #basket-items-title {
        margin-bottom: 0.5em;
        color: rgba(0,0,0,.85);
        font-weight: 600;
        font-size: 30px;
        line-height: 1.35;
    }
}

@media (min-width: 992px) {
    #basket-items-lg-table {
        position: absolute;
        width: 100%;
    }
}